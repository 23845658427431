<template>
  <div class="box-dialog">
    <el-dialog
      width="780px"
      :title="selfData.id ? '编辑加工商' : '新增加工商'"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" :rules="rules" :model="selfData" label-width="120px">
        <el-form-item label="公司" prop="company_name">
          <el-input v-model="selfData.company_name" size="mini" />
        </el-form-item>
        <el-form-item label="公司简称" prop="short_name">
          <el-input v-model="selfData.short_name" size="mini" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="selfData.remark" size="mini" />
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="selfData.address" size="mini" />
        </el-form-item>
        <el-form-item label="加工商联系人" prop="linkman">
          <el-input v-model="selfData.linkman" size="mini" />
        </el-form-item>
        <el-form-item label="手机号码" prop="linkman_phone">
          <el-input v-model="selfData.linkman_phone" size="mini" />
        </el-form-item>
        <el-form-item label="曾用名" prop="used_name">
          <el-input style="width: 100%" v-model="selfData.used_name" readonly size="mini" />
        </el-form-item>
        <el-form-item label="可提供工艺" prop="machine_list">
          <el-cascader
            v-model="selfData.machine_list"
            style="width: 100%"
            size="mini"
            filterable
            clearable
            :options="machineList"
            :props="{ multiple: true, value: 'name', label: 'name' }"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-switch active-value="激活" inactive-value="禁用" v-model="selfData.state" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {},
      machineList: [],
      rules: {
        company_name: [{ required: true, message: '请输入加工商名称', trigger: 'blur' }],
        linkman: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        linkman_phone: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }],
        remark: [{ required: false, message: '请输入备注', trigger: 'blur' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          this.selfData = this.$util.deepCopy(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
    this.machineTree()
  },
  methods: {
    // 获取工艺树状列表
    machineTree() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dict/machineTree'
      }).then((res) => {
        if (res.status === 200) {
          this.machineList = res.data.list
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          let url = '/admin/gcvip/processor/saveOrUpdate'
          this.$api({
            method: 'post',
            url: url,
            data: this.selfData
          }).then((res) => {
            if (res.data.state === 'ok') {
              if (res.data.msg === '创建失败,数据不能重复') {
                this.$message.error(res.data.msg)
              } else {
                this.popupCancel()
                this.$emit('success')
                this.$message.success('保存成功')
              }
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
