<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" import export print custom>
      <template #buttons>
        <el-button type="success" size="mini" @click="itemAdd">新增加工商 </el-button>
        <el-button type="danger" size="mini" @click="batchDelete">删除加工商 </el-button>
        <el-button type="primary" size="mini" @click="batchBan">激活/禁用加工商 </el-button>
      </template>
      <template #tools>
        <vxe-button
          type="text"
          title="模板下载"
          class="vxe-button type--button el-icon-notebook-2 is--circle"
          @click="downloadModule"
        />
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      show-overflow
      highlight-current-row
      :max-height="this.$util.getViewHeight() - 150"
      :data="tableDataMain"
      :import-config="importConfig"
      :columns="tableColumn"
      :mouse-config="{ selected: false }"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>
      <template #header_used_name="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          url="/admin/gcvip/processorStart/searchHeard"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_operate="{ row }">
        <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        <vxe-button
          icon="el-icon-connection"
          title="同步UUID"
          circle
          status="primary"
          @click="itemSync(row)"
        />
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 增改加工商弹窗 -->
    <PopupProcessor
      v-if="isShowPopupProcessor"
      :show.sync="isShowPopupProcessor"
      :data.sync="itemObj"
      @success="getListData"
    />

    <!-- 同步弹窗 -->
    <PopupSync v-if="isShowPopupSync" :show.sync="isShowPopupSync" :data.sync="itemSyncObj" />

    <!-- 导入弹窗 -->
    <PopupImport
      v-if="isShowPopupImport"
      :show.sync="isShowPopupImport"
      :isError="isError"
      :uuid="uuid"
      :list="importTableData"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupProcessor from './popup-processor'
import PopupSync from './popup-sync'
import PopupImport from './popup-import'
export default {
  components: {
    PopupProcessor,
    PopupSync,
    PopupImport
  },
  data() {
    return {
      remvoeIds: [],
      banIds: [],

      filterData: {},
      sortData: {},
      headerFilterUrl: '/admin/gcvip/processor/searchHeard',
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          field: 'id',
          title: '加工商编号',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'company_name',
          title: '加工商名称',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'used_name',
          title: '曾用名',
          slots: { header: 'header_used_name' }
        },
        {
          field: 'linkman',
          title: '联系人',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'linkman_phone',
          title: '联系电话',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'remark',
          title: '备注',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'state',
          title: '状态',
          slots: { header: 'header_autocomplete' }
        },
        {
          fixed: 'right',
          title: '操作',
          width: 120,
          slots: { default: 'default_operate' }
        }
      ],
      tableDataMain: [],
      importConfig: {
        remote: true,
        importMethod: this.importMethod,
        modes: ['insert'],
        type: ['xlsx'],
        types: ['xlsx']
      },
      pageOptions: {
        ...this.$constant.page
      },

      isShowPopupProcessor: false,
      itemDefault: {
        id: '',
        company_name: '',
        linkman: '',
        linkman_phone: '',
        remark: '',
        address: '',
        used_name: '',
        short_name: '',
        state: '激活',
        machine_list: [],
        limited_price: false
      },
      itemObj: {},

      isShowPopupSync: false,
      itemSyncObj: {
        id: '',
        company_uuid: '',
        customer_uuid: '', // 将要成为的客户uuid
        company_name: '' // 将要成为的客户名称
      },

      isShowPopupImport: false,
      isError: '',
      uuid: '',
      importTableData: []
    }
  },
  created() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联
      this.$refs.refTable.connect(this.$refs.refToolbar)
      this.initItemObj()
      this.getListData()
    })
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取数据列表
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/processor/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      }).then((res) => {
        if (res.status === 200) {
          this.tableDataMain = res.data.page.list
          this.pageOptions.pageSize = res.data.page.pageSize
          this.pageOptions.total = res.data.page.totalRow
          this.pageOptions.currentPage = res.data.page.pageNumber
        }
      })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupProcessor = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupProcessor = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-同步uuid
    itemSync(row) {
      this.isShowPopupSync = true
      this.itemSyncObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          // 传送删除动作
          this.$api({
            method: 'post',
            url: '/admin/gcvip/processor/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    // 批量-激活/禁用
    batchBan() {
      const $grid = this.$refs.refTable
      const selectRecords = $grid.getCheckboxRecords()
      this.banIds = []
      for (let i = 0; i < selectRecords.length; i++) {
        this.banIds.push(selectRecords[i].id)
      }
      if (selectRecords.length > 0) {
        this.$XModal
          .confirm('您确定要激活/禁用 ' + this.banIds.length + ' 个加工商?')
          .then((type) => {
            if (type === 'confirm') {
              // 传送删除动作
              this.$api({
                method: 'post',
                url: '/admin/gcvip/processor/banBatchVue',
                params: {
                  banIds: this.banIds
                }
              }).then((res) => {
                if (res.status === 200 && res.data.state === 'ok') {
                  this.$message.success('批量删除成功')
                  this.getListData()
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          })
      } else {
        this.$message.error('请先选择')
      }
    },
    // 批量-删除
    batchDelete() {
      const $grid = this.$refs.refTable
      const selectRecords = $grid.getCheckboxRecords()
      this.remvoeIds = []
      for (let i = 0; i < selectRecords.length; i++) {
        this.remvoeIds.push(selectRecords[i].id)
      }
      if (selectRecords.length > 0) {
        this.$XModal.confirm('您确定要删除 ' + this.remvoeIds.length + ' 条数据?').then((type) => {
          if (type === 'confirm') {
            // 传送删除动作
            this.$api({
              method: 'post',
              url: '/admin/gcvip/processor/deleteBatchVue',
              params: {
                remvoeIds: this.remvoeIds
              }
            }).then((res) => {
              if (res.status === 200 && res.data.state === 'ok') {
                this.$message.success('批量删除成功')
                this.getListData()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.$message.error('请先选择')
      }
    },
    // 模版下载
    downloadModule() {
      window.open('/template/supplier.xlsx', '_blank')
    },
    // 导入方法
    importMethod({ file }) {
      const formBody = new FormData()
      formBody.append('file', file)
      this.$api({
        method: 'post',
        url: '/admin/gcvip/processor/importExcel',
        data: formBody
      }).then((res) => {
        if (res.status === 200 && res.data.state === 'ok') {
          this.isError = res.data.isError
          if (res.data.isError == true) {
            this.importTableData = res.data.supplierListError
          } else {
            this.importTableData = res.data.supplierList
          }
          this.uuid = res.data.uuid
          this.isShowPopupImport = true
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
